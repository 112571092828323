import { useMemo } from 'react'
import { useAggregatorConfig } from 'src/data/queries/config'

export default function useAggregatorConfigCheck(keyName: string, version?: string) {
  const { isLoading, data: config } = useAggregatorConfig()
  const foundConfig = useMemo(
    () => config?.find((config) => config.key === keyName),
    [keyName, config]
  )

  return {
    isLoading,
    isConfigEnabled: !!foundConfig && foundConfig?.value !== 'null',
    isVersionMatching: (version && foundConfig?.value === version) || !version,
    value: foundConfig?.value,
  }
}
