import { useAuth } from 'src/auth'
import { useConfig } from 'src/data/config'
import { Img } from 'src/components/designsystem'
import Link from 'next/link'
import NoServerSideRendering from 'src/components/utils/NoServerSideRendering'
import { featuresAsLinks } from 'src/utils'
import getClient from 'src/utils/clients/get-client'
import { useBranding } from 'src/data/queries/config'

export default function BrandingLogo() {
  return (
    <NoServerSideRendering>
      {getClient().isNativeApp ? <BrandingLogoImage /> : <BrandingLogoLink />}
    </NoServerSideRendering>
  )
}

function BrandingLogoLink() {
  const { slug } = useAuth()
  const { config } = useConfig()
  return (
    <Link
      href={`/${slug}/${featuresAsLinks[config?.landing_feature] ?? featuresAsLinks.cash_bids}`}
      prefetch={false}
    >
      <BrandingLogoImage />
    </Link>
  )
}

function BrandingLogoImage() {
  const { data: branding } = useBranding()
  const { config } = useConfig()
  return (
    <Img
      src={branding?.logoStackedUrl ?? '/img/bushel-logo.png'}
      alt={config?.company?.company_name ?? 'Bushel Logo'}
      maxHeight="40px"
    />
  )
}
