import { useCallback } from 'react'
import { AppConfig } from 'src/api/centre-service/data-models/app-config'
import { useConfig } from 'src/data/config'
import { useAggregatorConfig } from 'src/data/queries/config'

export function useIsFeatureEnabledCheck() {
  const { data: aggregatorConfig } = useAggregatorConfig()
  const { isFeatureEnabled } = useConfig()
  return useCallback(
    (
      featureName: string | string[],
      extraEnabledCheck?: (config: AppConfig) => boolean,
      version?: string
    ) => {
      const isConfigEnabled = (featureName: string) => {
        const centreEnabled = isFeatureEnabled(featureName, extraEnabledCheck)

        const aggregatorConfigFound = aggregatorConfig?.find((config) => config.key === featureName)

        if (!aggregatorConfigFound) return centreEnabled

        const aggregatorEnabled = version
          ? aggregatorConfigFound.value === version
          : !!aggregatorConfigFound.value && aggregatorConfigFound.value !== 'null'

        return centreEnabled || aggregatorEnabled
      }

      return Array.isArray(featureName)
        ? featureName.some(isConfigEnabled)
        : isConfigEnabled(featureName)
    },
    [aggregatorConfig, isFeatureEnabled]
  )
}
