import type { AppContext } from 'next/app'
import * as api from 'src/api'
import { dehydrate } from '@tanstack/query-core'
import { updateBidlClientHeader } from 'src/api'
import {
  logger,
  ENV,
  getDevCentreApiUrl,
  getUserRedirectUri,
  getAccessCodeFromCookie,
  isValidAccessCode,
  featuresAsLinks,
} from 'src/utils'
import { getInitData, InitStatus } from 'src/data/bootstrap'
import { queryClient } from 'src/data/queries'
import { getAggregatorAppConfigQueryOptions } from 'src/data/queries/config'
import {
  App,
  AppProps,
  getRouteFacts,
  mergeProps,
  parseServerRequest,
  parseClientRequest,
  ParsedRequestData,
} from 'src/app'
import { FLAG_KEYS } from 'src/utils/flagr'
import { CONFIGCAT_FLAGS, configCatSSRClient } from 'src/utils/config-cat'
import { lookupResourcesOptions } from 'src/data/queries/authorization'

/**
 * This handler is called on _every_ load of the app.
 * It's responsible for all request parsing, validation, and handling.
 * Next.js does not support a `getServerSideProps()` in _app.tsx, so this is the next-best thing.
 * Migrating to a set of shared functions for page-level `getServerSideProps()` may be preferred.
 *
 * References:
 *  - https://nextjs.org/docs/api-reference/data-fetching/getInitialProps
 *  - https://github.com/vercel/next.js/discussions/10874
 *  - https://github.com/vercel/next.js/discussions/10949
 *  - https://github.com/vercel/next.js/discussions/26389
 */
/* istanbul ignore next */
export async function getInitialProps(context: AppContext) {
  // Populate the ConfigCat SSR client with the initial flags
  await configCatSSRClient.waitForReady()

  if (!context.ctx.req) {
    return handleClientRequest(context)
  }

  const {
    req,
    res,
    route,
    redirect,
    token,
    slug,
    selectedUserId,
    props: initProps,
    installationId,
    isClientRouteRequest,
    logContext,
  } = await parseServerRequest(context)

  const [shouldRedirectDomain, domainRedirectUrl] = shouldRedirectDomainToSlug({ req, slug })

  if (shouldRedirectDomain) {
    // Request arrived on custom domain, eg. www.admfarmview.com with no slug info, redirect to default slug welcome path
    return redirect(domainRedirectUrl, initProps)
  }

  const [isValid, props] = validateRequest({
    req,
    slug,
    route,
    logContext,
    props: initProps,
  })

  if (!isValid) {
    // Some part of validation failed, halt handling
    return props
  }

  // We have a valid slug, and the webapp is enabled for them

  if (slug) {
    // The initially created API client doesn't have a valid slug yet, so force update it
    updateBidlClientHeader('App-Company', slug)
    await queryClient.prefetchQuery(getAggregatorAppConfigQueryOptions)
  }

  // Temporary flag for prefetching tenant-level authz permissions
  const isTenantPermissionsPrefetchingEnabled = configCatSSRClient
    .snapshot()
    .getValue(CONFIGCAT_FLAGS.isTenantPermissionsPrefetchingEnabled, false)

  if (!isTenantPermissionsPrefetchingEnabled) {
    mergeProps(props, { dehydratedState: dehydrate(queryClient) })
  }

  const {
    isRootRoute,
    isAuthRoute,
    isSlugAccessRoute,
    isWelcomeRoute,
    isErrorRoute,
    isCashBids,
    isFutures,
    isSettings,
    isPleaseClosePage,
    isLocationsAndHours,
  } = getRouteFacts(route)

  if (isPleaseClosePage) {
    return { ...props, isPleaseClosePage }
  }

  if (isSlugAccessRoute) return props

  if (
    !token &&
    !isAuthRoute &&
    !isWelcomeRoute &&
    !isCashBids &&
    !isFutures &&
    !isSettings &&
    !isLocationsAndHours
  ) {
    // Unauthenticated, not logging in, not on welcome = redirect to welcome
    // if they have seen this screen before redirect them to the welcome page instead
    return redirect(`/${slug}/welcome`, props)
  }

  // Set api headers for SSR requests
  // Any page components that use `getServerSideProps` need these set
  api.updateCentreAppHeaders({ token, slug, installationId, selectedUserId })
  api.updatePaymentsServiceHeaders({ slug, token, selectedUserId })
  if (isTenantPermissionsPrefetchingEnabled) {
    api.updateBidlToken(token)
  }

  const centreTargetCookie = getDevCentreApiUrl(props?.cookies)

  if (centreTargetCookie) {
    api.updateCentreClient({ prefixUrl: centreTargetCookie })
  } else {
    api.updateCentreClient({ prefixUrl: ENV.CENTRE_API })
  }

  if (isClientRouteRequest) {
    logger.debug({ message: 'src/_app - gip - client route request', context: logContext })
    // Avoid re-loading init data for client-side routes
    return props
  }

  const hasAccessCodeBypass = isValidAccessCode(slug, getAccessCodeFromCookie(slug, props?.cookies))

  if (isTenantPermissionsPrefetchingEnabled) {
    const { tenantId } = queryClient.getQueryData<GetAggregatorAppConfigBySlugResponse>(
      getAggregatorAppConfigQueryOptions.queryKey
    )

    await queryClient.prefetchQuery(lookupResourcesOptions({ tenantId }))

    mergeProps(props, { dehydratedState: dehydrate(queryClient) })
  }

  // Load init data if SSR request
  const initData = await getInitData({ hasToken: !!token, slug, hasAccessCodeBypass })

  if (initData.status === InitStatus.NO_COMPANY) {
    res.statusCode = 404
    mergeProps(props, { isInvalidSlug: true })
    return props
  }

  // Update base props with `user`, `config`, and `flags`
  mergeProps(props, { user: initData.user, config: initData.config, flags: initData.flags })

  if (shouldRedirectLiteUser({ isWelcomeRoute, status: initData.status, config: props?.config })) {
    return redirect(
      `/${slug}/${featuresAsLinks[props?.config?.landing_feature] ?? featuresAsLinks.cash_bids}`,
      props
    )
  }

  if (isAuthRoute || (!token && isWelcomeRoute)) {
    // Auth routes and un-authenticated welcomes do their own thing
    return props
  }

  if (isCashBids || isFutures || isSettings || isLocationsAndHours) {
    // Futures are public
    // Cash bids are public, unless configured to be behind auth -- handled in the page
    // Settings are public
    return props
  }

  if (initData.status === InitStatus.NO_AUTH && !ENV.MOCK_MODE) {
    logger.debug({ message: 'src/pages/_app - gip - redirect no-auth', context: logContext })
    // Invalid token = redirect to login route for refresh attempt
    return redirect(['', slug, 'auth', 'logout'].join('/'), props)
  }

  if (initData.status === InitStatus.ERROR) {
    // A more general init data error occurred
    mergeProps(props, { error: initData.error })
    return props
  }

  if (initData.status === InitStatus.FORCE_LOGOUT) {
    api.updateCentreToken(null)

    if (!initData.config) {
      const initDataRefresh = await getInitData({
        slug,
        hasAccessCodeBypass: false,
        hasToken: false,
      })

      if (initDataRefresh.status === InitStatus.OK) {
        mergeProps(props, {
          user: null,
          config: initDataRefresh.config,
          flags: initDataRefresh.flags,
        })
      }
    }

    if (isAuthRoute || isWelcomeRoute) {
      mergeProps(props, { error: initData.error })
      return props
    }

    // This should be a client-side redirect, NOT a server-response redirect
    mergeProps(props, { redirectToPath: ['', slug, 'auth', 'logout'].join('/') })
    return props
  }

  // All preconditions ok

  if (isErrorRoute) {
    // Do not redirect if we're on an error route
    return props
  }

  if (isRootRoute) {
    logger.debug({ message: 'src/pages/_app - gip - redirect root-route', context: logContext })
    const landingFeature = props?.config?.landing_feature
    // Not on a route with a slug, so redirect to default feature
    return redirect(
      getUserRedirectUri({
        slug,
        user: initData.user,
        landingFeature,
        isStaffFlagEnabled: initData.flags?.[FLAG_KEYS.WEBAPP_STAFF_ROUTES],
      }),
      props
    )
  }

  return props
}

App.getInitialProps = getInitialProps

export default App

/**
 * All server-side app requests need to meet some criteria.
 *
 * This checks/ensures:
 *  - Is this a server-side request? If not, we bail
 *  - We were able to determine a company slug from the url
 *
 * If any of those criteria are not met, this returns "error props" at index 0.
 * Otherwise, it passes the props back to continue handling at index 1.
 */

type ValidateRequestArgs = Partial<ParsedRequestData> & {
  logContext: ReturnType<ParsedRequestData['buildLoggerContext']>
}

function validateRequest({
  req,
  slug,
  route,
  logContext,
  props,
}: ValidateRequestArgs): [boolean, AppProps] {
  if (!req) {
    // Bail early if this is not running server-side, but maintain base props
    logger.debug({ message: 'src/_app - gip ran client-side', context: logContext })
    return [false, props]
  }

  logger.debug({
    message: `src/_app - gip - [slug: ${slug}][route: ${route}][url: ${req.url}]`,
    context: logContext,
  })

  // Special case for the "please close" page
  if (route === '/please-close') return [true, props]

  if (typeof slug === 'undefined' || slug.length === 0) {
    logger.debug({ message: 'src/_app - gip - unknown slug', context: logContext })
    mergeProps(props, { error: 'Unknown Company' })
    return [false, props]
  }

  // All checks okay, pass props back
  return [true, props]
}

/**
 * Returns a bare-minimum of data for client navigations between pages.
 * See `parseClientRequest` definition for more info
 */
async function handleClientRequest(context: AppContext) {
  const { cookies, slug, route } = await parseClientRequest(context)

  return { cookies, slug, route }
}

/**
 * We have the concept of a custom domain pointing at our application. For instance, ADM has
 * www.admfarmview.com configured to run the application for their company. When a user visits
 * from that domain, they probably will not be supplying a slug on their initial request. The
 * Web Portal would typically show an "unknown company" error when no slug was supplied.
 *
 * This supports mapping those custom domains to a default slug. If the request was:
 *  - for a known custom domain, with a default slug (AKA: has a key in `CUSTOM_HOST_SLUG_MAPPING`)
 *  - on the root path of the app: '/'
 *  - there is no stored slug value
 *
 * then the user will be redirected to the default slug welcome path.
 *
 * Additionally, for companies running the Web Portal with a custom domain (eg. ADM: www.admfarmview.com),
 * we want to restrict these domains to only allow using their specified company slug.
 *
 * Example: www.admfarmview.com/bushelsalesdemo will redirect to www.admfarmview.com/adm
 *
 * This slug lockdown functionality is prevented when BUSHEL_ENVIRONMENT === 'local' to allow for local testing
 * against real backend services
 */

const CUSTOM_HOST_SLUG_MAPPING = {
  'localhost:3000': 'bushelsalesdemo',
  'wl-test-portal-dev.bushelops.com': 'bushelsalesdemo',
  'www.admfarmview.com': 'adm',
}

export function shouldRedirectDomainToSlug({
  req,
  slug,
}: Partial<ValidateRequestArgs>): [boolean, string?] {
  // This is the domain of the request (including subdomain) with no protocol or path, eg. `portal.bushelpowered.com`
  const requestHost = req.headers.host

  // Root path, with no persisted slug, and a matching custom domain
  if (req.url === '/' && !slug && CUSTOM_HOST_SLUG_MAPPING[requestHost]) {
    // On root path, with a custom domain
    return [true, ['', CUSTOM_HOST_SLUG_MAPPING[req.headers.host], 'welcome'].join('/')]
  }

  if (ENV.BUSHEL_ENVIRONMENT === 'local') return [false, null]

  if (
    !!slug &&
    CUSTOM_HOST_SLUG_MAPPING[requestHost] &&
    CUSTOM_HOST_SLUG_MAPPING[requestHost] !== slug
  ) {
    return [true, ['', CUSTOM_HOST_SLUG_MAPPING[requestHost]].join('/')]
  }

  return [false, null]
}

export function shouldRedirectLiteUser({ isWelcomeRoute, status, config }) {
  return isWelcomeRoute && status !== InitStatus.ERROR && !config?.features?.authentication?.version
}
