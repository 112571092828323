import { queryOptions, useQuery } from '@tanstack/react-query'
import * as api from 'src/api'

export function useGetCommoditySubgroups() {
  return useQuery({
    queryKey: ['get-commodity-subgroups'],
    queryFn: () => api.aggregatorService.getCommoditySubgroups(),
  })
}

export function useGetActiveUsersFromIdentifiers(ids: string[]) {
  return useQuery({
    queryKey: ['get-active-users-from-identifiers', ids],
    queryFn: () => api.aggregatorService.getActiveUsersFromIdentifiers(ids),
    enabled: ids?.filter(Boolean)?.length > 0,
  })
}

export const getAggregatorAppConfigQueryOptions = queryOptions({
  queryKey: ['get-aggregator-app-config-by-slug'],
  queryFn: () => api.aggregatorService.getAggregatorAppConfigBySlug(),
})

export function useTenantId() {
  return useQuery({
    ...getAggregatorAppConfigQueryOptions,
    select: (data) => data.tenantId,
  })
}

export function useGetAllStaffUsers() {
  return useQuery({
    queryKey: ['get-all-staff-users'],
    queryFn: () => api.aggregatorService.getAllStaffUsers(),
  })
}

export function useBranding() {
  return useQuery({
    ...getAggregatorAppConfigQueryOptions,
    select: (data) => data.data.branding,
  })
}

export function useAggregatorConfig() {
  return useQuery({
    ...getAggregatorAppConfigQueryOptions,
    select: (data) => data.data.config,
  })
}
